 
import StringUtils from "@/utils/StringUtils.js";
import RcDomainObject from "../../common/RcDomainObject";
import Address from "../address/Address.js";
import FeatureMap from "../feature/FeatureMap.js";
import CompanyMap from "./CompanyMap.js";
import User from "@/domain/model/user/User.js";
import RcTime from "../../session/time/RcTime.js";

export default class Company extends RcDomainObject {

  static MODEL_NAME = 'Company';

  static FIELDS = {
    NAME: 'name',
    WEBSITE: 'website',
    EMAIL: 'email',
    EMAIL_DEFECTS: 'emailDefects',
    EMAIL_EQUIPMENT_DEFECTS: 'emailEquipmentDefects',
    CREDIT: 'credit',
    CVOR_NUMBER: 'cvor',
    PHONE: 'phone',
    POSTAL_CODE: 'postal',
    ADDRESS: "Addressv2",
    FEATURES: FeatureMap.MODEL_NAME,
    COUNTRY: 'country',
    PROVINCE: 'jurisdiction',
    EMAIL_INVOICE: 'emailInvoice',
    CHARGE_INVOICE: 'chargeInvoice',
    DISCOUNT: 'discount',
    BAY: 'bay',
    PROMO: 'promoCode',
    CONTRACT_START: 'contractStart',
    BILLING_PERIOD: 'billingPeriod',
    STATE: 'state',
    INTEGRATION_REFERENCE: 'integrationReference',
    RENEWAL_DATE: 'cmvRegDate',
    API_KEY: 'apiKey',
    ADMIN_ORDER: 'order',
    ADMIN_SUMMARY: 'adminSummary',
    ADMIN_NOTES: 'adminNotes',
    ADDRESS_HQ: 'hq',
    ASSETS: 'AssetMap',
    AUTO_CHARGE_INVOICE: "chargeInvoice",
    AUTO_EMAIL_INVOICE: "emailInvoice",
  }

  static Values = {
    States: {
      ACTIVE: 'active',
      INACTIVE: 'inactive',
    },
    BillingPeriod: {
      MONTHLY: 'monthly',
    },
    Discount: {
      FULL: 1,
      HALF: 0.5,
      THIRD: 0.3,
      QUARTER: 0.25,
      FIFTH: 0.2,
      TENTH: 0.1,
    }
  }

  constructor(domain, data) {
    super(domain, data, Company.MODEL_NAME);
  }

  fields() {
    return Company.FIELDS;
  }

  find() {
    return this.domain.companys().findById(this.id());
  }

  update() {
    var update = this.find();
    this.data = update.data;
    return this;
  }

  order() {
    return this.getString(Company.FIELDS.ADMIN_ORDER);
  }

  apiKey() {
    return this.getString(Company.FIELDS.API_KEY);
  }

  name() {
    return this.getString(Company.FIELDS.NAME);
  }

  autoEmailInvoice() {
    return this.getBoolean(Company.FIELDS.AUTO_EMAIL_INVOICE);
  }

  autoChargeInvoice() {
    return this.getBoolean(Company.FIELDS.AUTO_CHARGE_INVOICE);
  }

  withName(name) {
    this.put(Company.FIELDS.NAME, name);
    return this;
  }

  registrationDateV2() {
    let rcDate = RcTime.time().at( this.getInt(Company.FIELDS.RENEWAL_DATE), this.timeZone() );
    return rcDate;
  }

  website() {
    return this.getString(Company.FIELDS.WEBSITE);
  }

  email() {
    return this.getString(Company.FIELDS.EMAIL);
  }

  emailDefects() {
    return this.getString(Company.FIELDS.EMAIL_DEFECTS);
  }

  emailEquipmentDefects() {
    return this.getString(Company.FIELDS.EMAIL_EQUIPMENT_DEFECTS);
  }

  cvorNumber() {
    return this.getString(Company.FIELDS.CVOR_NUMBER);
  }

  phone() {
    return this.getString(Company.FIELDS.PHONE);
  }

  postal() {
    return this.getString(Company.FIELDS.POSTAL_CODE);
  }

  country() {
    return this.getString(Company.FIELDS.COUNTRY);
  }

  credit() {
    return this.getString(Company.FIELDS.CREDIT);
  }

  adminSummary() {
    return this.getString(Company.FIELDS.ADMIN_SUMMARY);
  }

  adminNotes() {
    return this.getString(Company.FIELDS.ADMIN_NOTES);
  }

  creditDisplay() {
    var credit = this.credit();
    if (StringUtils.isEmpty(credit)) {
      credit = "0";
    }
    credit = parseFloat(credit);
    return StringUtils.format("${0}", [credit.toFixed(2)]);
  }

  stats() {
    return this.domain.companys().findStatsById(this.id());
  }

  address() {
    var obj = this.get(Company.FIELDS.ADDRESS);
    var address = new Address(this.domain, obj);
    return address;
  }

  addressHq() {
    var obj = this.get(Company.FIELDS.ADDRESS_HQ);
    var address = new Address(this.domain, obj);
    return address;
  }

  invoiceSettings() {
    var settings = this.domain.invoices().settings().findByCompany(this);
    return settings;
  }

  assets() {
    var assets = this.domain.assets().findByCompany(this);
    return assets;
  }

  features() {
    var featureMap = new FeatureMap(this.domain, this.get(Company.FIELDS.FEATURES));
    return featureMap;
  }

  owners() {
    return this.employees().owners();
  }

  employees() {
    return this.domain.employees().findByCompany(this.id());
  }

  copy() {
    var data = this._copy();
    return new Company(this.domain, data);
  }

  hasCheckIns() {
    return this.features().find().allowsCheckIns();
  }

  hasChecklists() {
    return this.features().find().allowsCheckLists();
  }

  hasChecklistsForVehicles() {
    return this.features().find().allowsChecklistsForVehicles();
  }

  hasInspections() {
    return this.features().find().allowsInspections();
  }

  hasTimesheets() {
    return this.features().find().allowsTimesheets();
  }

  hasVehicles() {
    return this.features().find().allowsVehicles();
  }

  vehicles() {
    return this.domain.vehicles().findByCompany(this);
  }

  hasProfileUpdate() {
    return this.features().find().allowsProfileUpdate();
  }

  hasSso() {
    return this.features().find().allowsSso();
  }

  hasOffline() {
    return this.features().find().allowsOffline();
  }

  hasCompliance() {
    return this.features().find().allowsCompliance();
  }

  hasEquipment() {
    return this.features().find().allowsEquipment();
  }

  hasEquipmentInspections() {
    return this.features().find().allowsEquipmentInspections();
  }

  hasHome() {
    return this.features().find().allowsHome();
  }

  hasMessages() {
    return this.features().find().allowsMessages();
  }

  hasUsers() {
    return this.features().find().allowsUsers();
  }

  emailInvoices() {
    return this.get(Company.FIELDS.EMAIL_INVOICE);
  }

  chargeInvoices() {
    return this.getBoolean(Company.FIELDS.CHARGE_INVOICE);
  }

  discount() {
    return this.getString(Company.FIELDS.DISCOUNT);
  }

  promo() {
    return this.getString(Company.FIELDS.PROMO);
  }

  hasPromo() {
    return StringUtils.isNotEmpty(this.promo());
  }

  contractStart() {
    return this.getString(Company.FIELDS.CONTRACT_START);
  }

  contractStartDate() {
    var date = this.domain.session().time().at(this.get(Company.FIELDS.CONTRACT_START));
    return date;
  }

  billingPeriod() {
    return this.getString(Company.FIELDS.BILLING_PERIOD);
  }

  billingPeriodDisplay() {
    if (this.billingPeriod() == "monthly") {
      return "Monthly";
    }
    if (this.billingPeriod() == "quarterly") {
      return "Quarterly";
    }
    if (this.billingPeriod() == "semi-annually") {
      return "Semi Annually";
    }
    if (this.billingPeriod() == "annually") {
      return "Annually";
    }
    return "Monthly";
  }
  
  bay() {
    return this.getString(Company.FIELDS.BAY);
  }


  hq() {
    return this.getString(Company.FIELDS.HQ);
  }

  withStateActive() {
    this.put(Company.FIELDS.STATE, Company.Values.States.ACTIVE);
    return this;
  }

  withBillingPeriodMonthly() {
    this.put(Company.FIELDS.BILLING_PERIOD, Company.Values.BillingPeriod.MONTHLY);
    return this;
  }

  withFullDiscount() {
    this.put(Company.FIELDS.DISCOUNT, Company.Values.Discount.FULL);
    return this;
  }

  hasDiscount() {
    return StringUtils.eic(Company.Values.Discount.FULL, this.discount());
  }

  integrationReference() {
    return this.get(Company.FIELDS.INTEGRATION_REFERENCE);
  }

  pointer() {
    var company = new Company(this.domain);
    company.withId(this.id());
    return company;
  }

  map() {
    var map = new CompanyMap(this.domain);
    map.add(this);
    return map;
  }

  employeeForUser(user) {
    return this.domain.employees().findForCompanyAndUser(this, user);
  }

  modifiedBy() {
    return new User(this.domain, this.get(RcDomainObject.DefaultFields.MODIFIED_BY));
  }

  createdBy() {
    return new User(this.domain, this.get(RcDomainObject.DefaultFields.CREATED_BY));
  }

  isPaymentMethodCreditCard() {
    return this.invoiceSettings().isPaymentMethodCreditCard();
  }

  hasCard() {
    return this.invoiceSettings().hasCard();
  }
}