<template>

  <Box cmpt="Value" v-on:click="pressed">
    <ValueRow :align="align" :fontSize="fontSize" :fontColour="fontColour" v-on:click="pressed" :spacer="spacer">
      <slot />
    </ValueRow>
  </Box>

</template>

<script>

import { mapGetters } from 'vuex'

import Box from "@/portals/shared/library/box/Box.vue";
import ValueRow from "@/portals/shared/library/value/ValueRow.vue";

export default {
  name: 'portals-shared-library-value-value',
  components: {
    Box,
    ValueRow,
  },
  props: {
    align: { type: String, default: "left" },
    fontSize: { type: String, default: "small" },
    fontColour: { type: String, default: "" },
    spacer: { type: Boolean, default: false },
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters([
                   'domain',
                 ]),
  },
  methods: {
    alignment: function() {
      if (this.align == "left") {
        return "rc-text-left";
      }
      if (this.align == "right") {
        return "rc-text-right";
      }
      return "rc-text-centre";
    },
    pressed: function() {
      this.$emit('click');
    },
  },
}
</script>