import StringUtils from '@/utils/StringUtils.js'
import Catalog from './Catalog';

export default class MC {
  static Langs = {
      EN_CA: "en_ca",
      FR_CA: "fr_ca",
      SP: "sp",
      EN_US: "en_us",
  };
  
  constructor(browserLanguage) {
    var language = window.navigator.userLanguage || window.navigator.language;
    this._catalog = new Catalog();
    if (language) {
      this.lang = language;
    }
    
    if (browserLanguage) {
      this.lang = browserLanguage;
    }
    
    
    this.CheckList = {
      Title: this.entry().withEnCa("Checklists"),
      Definition: this.entry().withEnCa("A checklist is a custom set of questions a company can create for different vehicles (in addition to any mandatory schedule parts lists).  For example, if you have special equipment or emergency items or additional tasks you want the driver to look at, you can create a list for the driver to check and select that list here.  To create a checklist, go to the Checklist link in the navigation menu (top) to create a checklist."),
      DefinitionShort: this.entry().withEnCa("A checklist is a custom set of questions a company can create for different vehicles (in addition to any mandatory schedule parts lists).  "),
      AddButton: this.entry().withEnCa("+ Checklist"),
      SaveButton: this.entry().withEnCa("Save"),
      CancelButton: this.entry().withEnCa("Cancel"),
      RefreshButton: this.entry().withEnCa("Refresh"),
      EditButton: this.entry().withEnCa("Edit"),
      DetailsButton: this.entry().withEnCa("Details"),
      AddQuestionButton: this.entry().withEnCa("+ Question"),
      RemoveQuestionButton: this.entry().withEnCa(" - "),
      AddOptionButton: this.entry().withEnCa("+ Option"),
      RemoveOptionButton: this.entry().withEnCa(" - "),
      OptionsTitle: this.entry().withEnCa("Answers"),
      SavingTitle: this.entry().withEnCa("Saving"),
      QuestionDescription: this.entry().withEnCa("In this section, you can add questions to the checklist that you would like to be answered. There are 4 types of questions you can add.  1) Text - allows the user to give a simple text answer to the question.  2) Number - allows the user to enter a number value.  3) Yes/No which allows the user to pick one of two possible values (these can be customized - e.g. Yes/No, True/False).  4) List - allows the user to pick either one or more values from a set of responses.  All values will also still allow for \"Remarks\" to add context or extra details if they use the \"Report\" button in the app for that question."),
      Add: {
        Title: this.entry().withEnCa("Add a new Checklist"),
        ChecklistItemMultiSelectLabel: this.entry().withEnCa("Allow user to pick more than one answer"),
        IndexLabel: this.entry().withEnCa("Index"),
        IndexHint: this.entry().withEnCa(""),
        CheckListItemTitle: this.entry().withEnCa("Checklist Questions"),
        NameLabel: this.entry().withEnCa("Name"),
        NameHint: this.entry().withEnCa(""),
        DescriptionLabel: this.entry().withEnCa("Description"),
        DescriptionHint: this.entry().withEnCa(""),
        QuestionNameLabel: this.entry().withEnCa("Question Text"),
        QuestionNameHint: this.entry().withEnCa(""),
        ChecklistItemTypeLabel: this.entry().withEnCa("Type"),
        ChecklistItemTypeHint: this.entry().withEnCa(""),
        OptionLabel: this.entry().withEnCa("Option"),
        OptionTextLabel: this.entry().withEnCa("Option Text"),
        OptionTextHint: this.entry().withEnCa(""),
        ChecklistItemOptionCategoryLabel: this.entry().withEnCa("Category"),
        ChecklistItemOptionCategoryHint: this.entry().withEnCa(""),
      },
      Status: {
        Loading: this.entry().withEnCa("Loading Checklists please wait..."),
      }
      
    }
    this.Template = {
        Title:  this.entry("Template Title", null, null ),
    }
    
    this.Filters = {
        Title:  this.entry("Filters", null,   null ),
        States: this.entry( "States", null,   null ),
        All:    this.entry(    "All", "Tout", null, ),
        Search: this.entry( "Search", null,   null),
        Paying: this.entry( "Paying", null,   null),
        NotPaying: this.entry( "Not Paying", null,   null),
        Clear: this.entry( "Clear", null,   null),
        Go: this.entry( "Go", null,   null),
        Active: this.entry( "Active", null,   null),
        Inactive: this.entry( "Inactive", null,   null),
        Feature: this.entry().withEnCa("Features"),
        Inspections: this.entry().withEnCa("Inspections"),
        Hours: this.entry().withEnCa("Hours"),
        Checklists: this.entry().withEnCa("Checklists"),
        Sso: this.entry().withEnCa("SSO"),
    }
    
    this.Actions =  {
        Title:   this.entry("Actions", null, null),
        Add:     this.entry("Add", null, null),
        Details: this.entry("Details", null, null),
        Rebuild: this.entry("Rebuild", null, null),
        Repair:  this.entry("Repair", null, null),
        Refresh: this.entry("Refresh", "Rafra\u00EEchir", null),
        AddRemarks: this.entry("+ Remarks", null, null),
        EmailMto: this.entry("Email", null, null),
        PrintDownload: this.entry().withEnCa("Print / Download"),
        Delete: this.entry().withEnCa("Delete"),
    }
    
    this.States = {
        All: this.entry("All", null, null),
    }
    
    this.Menu = {
        Details: this.entry("Details", null, null),
    }
    
    this.Status =  {
        Loading: this.entry("Loading please wait...", null, null),
        Done: this.entry("Done!", null, null),
        Connecting: this.entry("Reconnecting to ReadyChek...", null, null),
        CreatingSession: this.entry("Creating session...", null, null),
        FindingYou: this.entry("Finding you...", null, null),
        FindingSchedules: this.entry("Finding Schedules...", null, null),
        FindingInspection: this.entry("Finding Inspections...", null, null),
        FindingHoursOfService: this.entry("Finding Hours of Service...", null, null),
        StoringData: this.entry("Storing data...", null, null),
    }
    
    this.Schedule = {
        Title: this.entry("Schedules", null, null),
        Description: this.entry("Description", null, null),
        Declaration: this.entry("Declaration", null, null),
        Notes: this.entry("Notes", null, null),
        Options: {
          Majors: this.entry("Majors", null, null),
          Minors: this.entry("Minors", null, null),
        },
        Status: {
          Loading: this.entry("Loading schedules and lists please wait...", null, null),
        }

    }
    
    this.Inspection = {
        Title: this.entry("Inspections", null, null),
        DriverNotSet: this.entry("<Driver Not Set>"),
        
        Email: {
          PageTitle: this.entry("Email Inspection", null, null),
          MtoMessageStart: this.entry("This will email this inspection for this vehicle in an electronic format (pdf) to the specified email address.", null, null),
          MtoMessageEnd: this.entry("Please note that it will take a few minutes for the email to be generated and delivered.", null, null),
          Email: this.entry("Email", null, null),
          Progress: {
            Title: this.entry("Sending Email", null, null),
            Message: this.entry("Sending Email this may a momemnt to generate and deliver the email, please wait...", null, null),
          }
        },
        
        Status: {
          Loading: this.entry("Loading inspections please wait...", null, null),
          Details: this.entry("Loading inspection details please wait...", null, null),
          Good: this.entry("Good", null, null),
          Minor: this.entry("Minor", null, null),
          Major: this.entry("Major", null, null),
        },
        
        Remarks: {
          Title: this.entry().withEnCa("Inspection Remarks"),
          Progress: {
            Title: this.entry().withEnCa("Saving Inspection"),
            Message: this.entry().withEnCa("Saving Inspection with your remarks, please wait..."),
          },
          Message: this.entry().withEnCa("Enter the text for the remark you'd like to add."),
          Remarks: this.entry().withEnCa("Additional Remarks or Comments"),
        },
        
        Details: {
          Title: this.entry().withEnCa("Inspection Details"),

          NoDefectsFound:    this.entry().withEnCa("No defects have been found on this vehicle and it is safe to operate."),
          MinorDefectsFound: this.entry().withEnCa("The Minor Defects found do not need to be corrected to operate this vehicle."),
          MajorDefectsFound: this.entry().withEnCa("The Major Defects found MUST be corrected. This vehicle is Out of Service and must not be operated."),
          
          PreTripDefect:  this.entry().withEnCa("Initial Inspection Results"),
          PostTripDefect: this.entry().withEnCa("Initial Inspection Results"),
          InTripDefect:   this.entry().withEnCa("Enroute Inspection Results"),
          
          CompanyLabel: this.entry().withEnCa("Company Details"),
          NameLabel: this.entry().withEnCa("Name"),
          AddressLabel: this.entry().withEnCa("Address"),
          CvorNumberLabel: this.entry().withEnCa("Reg/CVOR #"),
          DateLabel: this.entry().withEnCa("Date"),
          InspectedOnLabel: this.entry().withEnCa("Inspected On"),
          TripTypeLabel: this.entry().withEnCa("Trip Type"),
          PreTripValue: this.entry().withEnCa("Pre-Trip"),
          PostTripValue: this.entry().withEnCa("Post-Trip"),
          StatusLabel: this.entry().withEnCa("Status"),
          VehicleLabel: this.entry().withEnCa("Vehicle Inspection Details"),
          ChecklistLabel: this.entry().withEnCa("Checklist"),
          DescriptionLabel: this.entry().withEnCa("Description"),
          TrailerLabel: this.entry().withEnCa("Trailers"),
          RemarksLabel: this.entry().withEnCa("Remarks"),
          InitialLabel: this.entry().withEnCa("Initial Inspection By"),
          DriverLabel: this.entry().withEnCa("Driven By"),
          InTripLabel: this.entry().withEnCa("Enroute By"),
          QuestionTitle: this.entry().withEnCa("Questions"),
          ScheduleTitle: this.entry().withEnCa("System and Components"),
          TrailerNumberLabel: this.entry().withEnCa("Trailer #"),
          TrailerNumberValue: this.entry().withEnCa("{0} of {1}"),
          AttachedLabel: this.entry().withEnCa("Attached"),
          DetachedLabel: this.entry().withEnCa("Detached"),
          AttachedOnLabel: this.entry().withEnCa("Attached On"),
          DetachedOnLabel: this.entry().withEnCa("Detached On"),
          YesValue: this.entry().withEnCa("Yes"),
          NoValue: this.entry().withEnCa("No"),
          
          SignatureLabel: this.entry().withEnCa("Signature"),
          
          ScheduleLabel: this.entry().withEnCa("Schedule"),
          OdometerLabel: this.entry().withEnCa("Odometer"),
          HubometerLabel: this.entry().withEnCa("Hubometer"),
          PlateLabel: this.entry().withEnCa("Plate"),
          JurisdictionLabel: this.entry().withEnCa("Province").withEnUs("State"),
          
          RemarkDateLabel: this.entry().withEnCa("Remark Date"),
          RemarkByLabel: this.entry().withEnCa("Remark By"),
          RemarkLabel: this.entry().withEnCa("Remark"),
          
          InitialTitle: this.entry().withEnCa("Initial Inspection Details"),
          InspectedByLabel: this.entry().withEnCa("Inspected By"),
          DeclarationLabel: this.entry().withEnCa("I declare that the vehicles listed have been inspected in accordance with {0}"),
          
          DrivenByTitle: this.entry().withEnCa("Driver Inspection Details"),
          DrivenByLabel: this.entry().withEnCa("Driven By"),
          
          EnrouteByTitle: this.entry().withEnCa("Enroute Inspection Details"),
          EnrouteByLabel: this.entry().withEnCa("Enroute By"),
          RepairTitle: this.entry().withEnCa("Repair Details"),
          RepairedByLabel: this.entry().withEnCa("Repaired By"),
          WorkOrdersLabel: this.entry().withEnCa("Work Orders"),
          RepairsMadeLabel: this.entry().withEnCa("Repairs Made"),
          MajorDefectsLabel: this.entry().withEnCa("Major Defects"),
          MinorDefectsLabel: this.entry().withEnCa("Minor Defects"),
          RepairIndexLabel: this.entry().withEnCa("Repair #"),
          RebuildingMessage: this.entry().withEnCa("Rebuilding PDF document, please wait..."),
        }
        
    }
    
    this.Invoice = {
        Title: this.entry("Invoices", null, null),
        Status: {
          Loading: this.entry("Loading invoices please wait...", null, null),
        }
    }
    
    this.Timesheet = {
        Title: this.entry("Hours of Service", null, null),
        Status: {
          Loading: this.entry("Loading hours please wait...", null, null),
          Missing: this.entry("Missing", null, null),
          OffDay: this.entry("Off Day", null, null),
          Complete: this.entry("Complete", null, null),
          InProgress: this.entry("In Progress", null, null),
          Incomplete: this.entry("Incomplete", null, null),
        }
    }
    
    this.CheckIn = {
        Title: this.entry("Check-Ins", null, null),
        Status: {
          Loading: this.entry("Loading check-ins please wait...", null, null),
          Good: this.entry("Good", null, null),
          Sick: this.entry("Sick", null, null),
        }
    }
    
    this.User = {
        Back: this.entry("Back", null, null),
        LicenseGood: this.entry("License Good", null, null),
        LicenseExpired: this.entry("License Expired", null, null),
        LicenseClassMissing: this.entry("License Class Missing", null, null),
        LicenseExpiryMissing: this.entry("License Expiry Missing", null, null),
        LicenseExpiresInXDays: this.entry("License expires in {0} days", null, null),
        Status: {
          Loading: this.entry("Loading users please wait...", null, null),
        },
        Remove: {
          Title: this.entry("Remove User", null, null),
          Confirm: this.entry("Confirm", null, null),
          Remove: this.entry("Remove", null, null),
          Message: this.entry("Are you sure you want to remove user {0}?", null, null),
          InsuranceReminder: this.entry("Please remember to update the business insurance policy if this person no longer employed by {0}", null, null),
        },
        Restore: {
          Title: this.entry("Restore User", null, null),
          Confirm: this.entry("Confirm", null, null),
          Restore: this.entry("Restore", null, null),
          Message: this.entry("Are you sure you want to restore {0}?", null, null),
          InsuranceReminder: this.entry("Please remember to update the business insurance policy if this person is newly employed by {0}", null, null),
        },
    }
    
    this.Vehicle = {
        Edit: {
          Title:  this.entry().withEnCa("Update Vehicle"),
          TrailerTitle:  this.entry().withEnCa("Update Trailer"),
        },
        Add: {
          PlateExpiryDateLabel: this.entry().withEnCa("Plate Expiry Date"),
          PlateExpiryDateHint: this.entry().withEnCa(""),
          
          ServiceStartAndEndTitle: this.entry().withEnCa("Start and End of Operation"),
          StartDateLabel: this.entry().withEnCa("Start Date"),
          StartDateHint: this.entry().withEnCa("The day the vehicle or trailer started operating in your fleet"),
          
          EndDateLabel: this.entry().withEnCa("End Date"),
          EndDateHint: this.entry().withEnCa("The day the vehicle or trailer ended operating in your fleet"),
          
          Title:  this.entry().withEnCa("Add Vehicle or Trailer"),
          OdometerAndMoreTitle:  this.entry().withEnCa("Odometer, Make, Model and Colour"),
          
          SafetyTitle: this.entry().withEnCa("Safety Information"),
          VinWeightTitle: this.entry().withEnCa("VIN and Weight"),
          
          Picture: this.entry().withEnCa("Picture"),
          Details: this.entry().withEnCa("Details"),
          RequiredDetails: this.entry().withEnCa("Details"),
          OptionalDetails: this.entry().withEnCa("Additional Details (optional)"),
          Loading: this.entry().withEnCa("Loading please wait..."),
          VehicleType: this.entry().withEnCa("Vehicle Type"),
          BrakeType: this.entry().withEnCa("Brake Type"),
          Name: this.entry().withEnCa("Name"),
          Vin: this.entry().withEnCa("VIN"),
          Units: this.entry().withEnCa("Units"),
          Summary: this.entry().withEnCa("Attributes"),
          NotesLabel: this.entry().withEnCa("Notes"),
          NotesHint: this.entry().withEnCa("Additional information needed for this vehicle"),
          SummaryHint: this.entry().withEnCa("Common name (Joe's Truck) or an attribute (Crew Cab, 10 Passenger)"),
          Colour: this.entry().withEnCa("Colour"),
          Odometer: this.entry().withEnCa("Odometer"),
          OdometerHint: this.entry().withEnCa("Current value of the odometer"),
          Hubometer: this.entry().withEnCa("Hubometer"),
          Make: this.entry().withEnCa("Make"),
          MakeHint: this.entry().withEnCa("Ford, GMC, International, Easy Hauler, etc"),
          Model: this.entry().withEnCa("Model"),
          ModelHint: this.entry().withEnCa("F-150, F-550, 2500 etc"),
          Year: this.entry().withEnCa("Year"),
          RegisteredGrossVehicleWeight: this.entry().withEnCa("Gross Vehicle Weight Rating"),
          Weight: this.entry().withEnCa("Registered Gross Weight"),
          Plate: this.entry().withEnCa("Plate"),
          Jurisdiction: this.entry().withEnCa("Province").withEnUs("State"),
          Checklist: this.entry().withEnCa("Checklist"),
          Schedule: this.entry().withEnCa("Schedule"),
          SaveAnotherButton: this.entry().withEnCa("Save and Add Another"),
          Pmvi: this.entry().withEnCa("Last Safety Date"),
          PmviHint: this.entry().withEnCa("Last semi or annual safety date"),
          PmviId: this.entry().withEnCa("Last Safety Id"),
          PmviIdHint: this.entry().withEnCa("Some semi or annual safeties have identification numbers"),
          
        },
        Title: this.entry("Vehicles", null, null),
        Back: this.entry("Back", null, null),
        Status: {
          Loading: this.entry("Loading vehicles please wait...", null, null),
        },
        Confirm: this.entry("Confirm", null, null),
        Remove: {
          Title: this.entry("Remove Vehicle", null, null),
          Remove: this.entry("Remove", null, null),
          Message: this.entry("Are you sure you want to remove vehicle {0} ({1})?", null, null),
        },
        Restore: {
          Title: this.entry("Restore Vehicle", null, null),
          Restore: this.entry("Restore", null, null),
          Message: this.entry("Are you sure you want to restore vehicle {0} ({1})?", null, null),
        },
        ChecklistLabel: this.entry().withEnCa("Checklist"),
    }
    
    this.Company = {
        Title: this.entry("Companies", null, null),
        Status: {
          Loading: this.entry("Loading company details please wait...", null, null),
        },
        Select: {
          Title: this.entry().withEnCa("Select a Company"),
          Message: this.entry().withEnCa("You have an account in more than on company please select the company you would like to access."),
        }
    }
    
    this.Compliance = {
        Title: this.entry("Compliance", null, null),
        Confirm: this.entry("Confirm", null, null),
        Status: {
          LoadingEmployees: this.entry("Loading users please wait...", null, null),
          LoadingRest: this.entry("Loading other items please wait...", null, null),
        },
        Reminder: {
          Title: this.entry("Send Reminder Email", null, null),
          Message: this.entry("Would you like to send {0} a reminder email to complete the missing Hours of Service?", null, null),
          Send: this.entry("Send Reminder", null, null),
          Back: this.entry("Back", null, null),
        },
    }
    
    this.Stats = {
        Title: this.entry("Stats", null, null),
        Status: {
          LoadingToday: this.entry("Loading todays stats please wait...", null, null),
          LoadingThisMonth: this.entry("Loading this months stats please wait...", null, null),
          LoadingDaily: this.entry("Loading last 15 days please wait...", null, null),
          LoadingMonthly: this.entry("Loading last 15 months please wait...", null, null),
        }
    }
    this.Sign = {
        Back: this.entry("No", null, null),
        Confirm: this.entry("Yes", null, null),
        Out: {
          Title: this.entry("Sign Out?", null, null),
          Message: this.entry("Are you sure you would like to sign out?", null, null),
          SignOut: this.entry("Sign Out", null, null),
          Confirm: this.entry("Yes", null, null),
        },
        In: {
          SignIn: this.entry("Sign In", null, null),
        },
    }
    this.LostPassword = {
        Send: this.entry("Send", null, null),
        Email: this.entry("Email", null, null),
        Title: this.entry("Lost Password", null, null),
        Message: this.entry("Enter your email address and a temporary password will be sent.", null, null),
    }
    this.Errors = {
        
    }
  }
  
  entry(en_ca, fr_ca, sp) {
    return new McEntry(this.lang, en_ca, fr_ca, sp);
  }
  
  error(error) {
    var entry = this.Errors[error.messageId];
    if (entry == null) {
      entry = new McEntry(MC.Langs.EN_CA).withEnCa(error.hint);
    }
    return entry;
  }
  
  get(id, hint) {
    var value = Catalog.MESSAGES[id];
    if (!value) {
      value = this._catalog.get(id, hint);
      if (!value) {
        value = id;
        if (hint) {
          value = hint;
        }
      }
    }
    if (!value) {
      value = id;
    }
    
    return value;
  }
}

export class McEntry {
  constructor(lang, en_ca, fr_ca, sp) {
    this.lang = lang;
    this.en_ca = en_ca;
    this.fr_ca = fr_ca;
    this.sp = sp;
  }
  
  withEnCa(value) {
    this.en_ca = value;
    return this;
  }
 
  withEnUs(value) {
    this.en_us = value;
    return this;
  }
  
  withFrCa(value) {
    this.fr_ca = value;
    return this;
  }
  
  value(params) {
    var result = this.en_ca;
    if (this.lang == "fr_ca" && this.fr_ca) {
      result = this.fr_ca;
    }
    
    if (this.lang == "sp" && this.sp) {
      result = this.sp;
    }
    
    if (!params) {
      params = ""
    }
    return StringUtils.format(result, params);
  }
}