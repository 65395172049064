<template>
  <Box>
  
    <Row v-if="auth_user">
      <Column :width="2" />
      <Column :width="11" :key="redraw" >
        <Box borderStyle="rc-border-thin-off">
          <Row>
            <!-- <Column :width="1" style="padding-top: 12px">
              <span style="padding: 5px;">Menu</span>
            </Column> -->
            <Column :width="15" style="padding-top: 3px">
              <span :class="getClasses('home')" v-on:click="withButtonPressed({field: 'home'})">Home</span>
              <span :class="getClasses('whatsnew')" v-on:click="withButtonPressed({field: 'whatsnew'})">Whats New?</span>
              <span v-if="compliance" :class="getClasses('compliance')" v-on:click="withButtonPressed({field: 'compliance'})">Compliance</span>
              <span v-if="vehicles" :class="getClasses('vehicles')" v-on:click="withButtonPressed({field: 'vehicles'})">Vehicles & Trailers</span>
              <span v-if="equipment" :class="getClasses('equipment')" v-on:click="withButtonPressed({field: 'equipment'})">Equipment</span>
              <span v-if="inspections" :class="getClasses('inspections')" v-on:click="withButtonPressed({field: 'inspections'})">Inspections</span>
              <span v-if="timesheets" :class="getClasses('hours')" v-on:click="withButtonPressed({field: 'hours'})">Hours of Service</span>
              <span :class="getClasses('users')" v-on:click="withButtonPressed({field: 'users'})">Users</span>
              <span v-if="checklists" :class="getClasses('checklists')" v-on:click="withButtonPressed({field: 'checklists'})">Checklists</span> 
              <span v-if="inspections" :class="getClasses('reports')" v-on:click="withButtonPressed({field: 'reports'})">Reports</span> 
              <span v-if="!equipment" :class="getClasses('invoices')" v-on:click="withButtonPressed({field: 'invoices'})">Invoices</span> 
              <span v-if="!equipment" :class="getClasses('billing/display')" v-on:click="withButtonPressed({field: 'billing/display'})">Billing</span>
              <span v-if="equipment" :class="getClasses('billing/list')" v-on:click="withButtonPressed({field: 'billing/list'})">Billing & Invoices</span>
              <span :class="getClasses('company/edit')" v-on:click="withButtonPressed({field: 'company/edit'})">Company</span>
              <!-- <span :class="getClasses('company/display')" v-on:click="withButtonPressed({field: 'company/display'})">Company</span> -->
            </Column>
          </Row>
        </Box>
      </Column>
      <Column :width="2" />
    </Row>
    <Row v-else>
    </Row>
    
  </Box>
</template>

<script>
import { mapGetters } from 'vuex';
import ContentUtils from '@/utils/ContentUtils.js';
import StringUtils from '@/utils/StringUtils.js';

import Box from "@/portals/shared/library/box/Box.vue";
import Row from "@/portals/shared/library/box/Row.vue";
import Column from "@/portals/shared/library/box/Column.vue";

export default {
  name: 'app-menu',
  components: {
    Box, Row, Column,
  },
  data() {
    return {
      redraw: 1,
    };
  },
  props: {
    isSmall: { type: Boolean, default: false },
  },
  computed :{
    ...mapGetters([
                   'auth_user', 
                   'auth_client',
                   'auth_connected',
                   'domain',
                 ]),
    companyName: function() {
      if (!this.domain || !this.domain.session()) {
        return "";
      }
      var name = this.domain.session().company().name();
      return ContentUtils.unescape(name);
    },
    companyId: function() {
      if (!this.domain || !this.domain.session()) {
        return "";
      }
      var name = this.domain.session().company().id();
      return ContentUtils.baseEncode(name);
    },
    inspections: function() {
      if (!this.domain || !this.domain.session()) {
        return false;
      }
      var result = true;
      result = this.domain.session().company().features().find().allowsInspections();
      return result;
    },
    timesheets: function() {
      if (!this.domain || !this.domain.session()) {
        return false;
      }
      var result = true;
      result = this.domain.session().company().features().find().allowsTimesheets();
      return result;
    },
    checkins: function() {
      if (!this.domain || !this.domain.session()) {
        return false;
      }
      var result = true;
      result = this.domain.session().company().features().find().allowsCheckIns();
      return result;
    },
    checklists: function() {
      if (!this.domain || !this.domain.session()) {
        return false;
      }
      var result = true;
      result = this.domain.session().company().features().find().allowsCheckLists();
      return result;
    },
    vehicles: function() {
      if (!this.domain || !this.domain.session()) {
        return false;
      }
      var result = true;
      if (this.inspections) {
        return true;
      }
      result = this.domain.session().company().features().find().allowsVehicles();
      return result;
    },
    equipment: function() {
      if (!this.domain || !this.domain.session()) {
        return false;
      }
      var result = true;
      result = this.domain.session().company().features().find().allowsEquipment();
      return result;
    },
    schedules: function() {
      return true;
    },
    compliance: function() {
      if (!this.domain || !this.domain.session()) {
        return false;
      }
      var result = true;
      result = this.domain.session().company().features().find().allowsCompliance();
      return result;
    },
    hasCompanys: function() {
      var user = this.domain.session().user();
      var employees = this.domain.employees().findByUserId(user.id());
      return employees.length() > 1;
    },
    isAdmin() {
      var user = this.domain.session().user();
      var employees = this.domain.employees().findByUserId(user.id());
      var roles = employees.roles().find();
      return roles.isAdministrator();
    }
  },
  watch: {
    $route (to, from){
      if (to && from) {
        //
      }
      this.redraw++;
    },
  },
  methods: {
    getClasses(path) {
      let routePath = this.$route.path;
      let selected = false;
      if (routePath) {
        selected = StringUtils.cic(routePath, path);
      }
      if (selected) {
        return "rc-main-menu rc-input-button-clear-v2-selected rc-input-width-menu"
      }
      return "rc-main-menu clickable rc-input-button-clear-v2 rc-input-width-menu";
    },
    decode: function(value) {
      return ContentUtils.unescape(value);
    },
    first: function(){
      if (!this.domain || !this.domain.session()) {
        return "";
      }
      var name = this.domain.session().user().firstDisplay();
      return name;
    },
    withButtonPressed(buttonData) {
      if (!buttonData) {
        return;
      }
      window.console.log(JSON.stringify(buttonData, null, 2));
      let path = buttonData["field"];
      let newPath = "/portal/operator/" + this.companyId + "/" + path;
      this.$router.push({ path: newPath })
    },
    displayType() {
      return "clearAction";
    },
    email: function() {
      if (!this.domain || !this.domain.session()) {
        return "";
      }
      return this.domain.session().user().emailDisplay();
    },
  },
}
</script>