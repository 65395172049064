
import RcDomainObject from "../../common/RcDomainObject";

export default class Feature extends RcDomainObject {
  
  static MODEL_NAME = "Feature";
      
  static FIELDS = {
    NAME : 'name',
    Price: 'price',
  }
  
  constructor(domain, data) {
    super(domain, data, Feature.MODEL_NAME);
  }

  fields() {
    return Feature.FIELDS;
  }
  
  find() {
    return this.domain.features().findById(this.id());
  }
  
  name() {
    return this.getString(Feature.FIELDS.NAME);
  }
  
  nameDisplay() {
    if (this.isVehicles()) {
      return "Vehicles";
    }
    if (this.isTimesheets()) {
      return "Hours of Service";
    }
    if (this.isEquipment()) {
      return "Equipment";
    }
    if (this.isInspections()) {
      return "Inspections";
    }
    return this.name();
  }
  
  price() {
    return this.getString(Feature.FIELDS.Price);
  }
  
  isInspections() {
    return this.name() === "com.readychek.feature.inspections";
  }
  
  isTimesheets() {
    return this.name() === "com.readychek.feature.timesheets";
  }
  
  isVehicles() {
    return this.name() === "com.readychek.feature.vehicles";
  }
  
  isUsers() {
    return this.name() === "com.readychek.feature.users";
  }
  
  isCheckIns() {
    return this.name() === "com.readychek.feature.checkins";
  }
  
  isCheckLists() {
    return this.name() === "com.readychek.feature.checklists";
  }
  
  isChecklistsForVehicles() {
    return this.name() === "com.readychek.feature.scheduleoptional";
  }

  isMessages() {
    return this.name() === "com.readychek.feature.messages";
  }
  
  isHome() {
    return this.name() === "com.readychek.feature.home";
  }
  
  isCompliance() {
    return this.name() === "com.readychek.feature.compliance";
  }

  isEquipment() {
    return this.name() === "com.readychek.feature.equipment";
  }

  isEquipmentInspections() {
    return this.name() === "com.readychek.feature.equipment.inspections";
  }

  isSso() {
    return this.name() === "com.readychek.feature.employmentid";
  }
  
  isOffline() {
    return this.name() === "com.readychek.feature.offline";
  }
  
  isProfileUpdate() {
    return this.name() === "com.readychek.feature.profile.update";
  }
  
  copy() {
    var data = this._copy();
    return new Feature(this.domain, data);
  }
  
  pointer() {
    var data = {'@rid': this.id()};
    var value = new Feature(this.domain, data);
    return value;
  }
}