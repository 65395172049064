
import RcDomainMap from "../../common/RcDomainMap.js";
import Company from './Company.js';
import CompanyUtils from './CompanyUtils.js';

export default class CompanyMap extends RcDomainMap {
  
  static MODEL_NAME = "CompanyMap";
  
  constructor(domain, data) {
    super(domain, data, CompanyMap.MODEL_NAME);
  }

  copy() {
    var data = this._copy();
    return new CompanyMap(this.domain, data);
  }
  
  sortByName() {
    var list = this.list();
    let functionName = 'SortBy_nameAz';
    var func = CompanyUtils[functionName];
    return list.sort(func);
  }
  
  sortBy(fieldName) {
    var list = this.list();
    let functionName = 'SortBy_' + fieldName;
    var func = CompanyUtils[functionName];
    return list.sort(func);
  }

  find() {
    var keys = this.keys();
    var found = new CompanyMap(this.domain, {} );
    for (var index = 0; index < keys.length; index++) {
      var key = keys[index];
      var data = this.domain.companys().findById(key);
      var item = new Company(this.domain, data);
      if (!item.isNew()) {
        found.add(item);
      }
    }
    return found;
  }
  
  list() {
    var values = [];
    var keys = this.keys();
    for (var index = 0; index < keys.length; index++) {
      var key = keys[index];
      var item = this.getCompany(key);
      values.push(item);
    }
    return values;
  }
  
  first() {
    var all = this.list();
    if (all.length > 0) {
      return all[0];
    }
    return new Company(this.domain);
  }
  
  getCompany(key) {
    var value = super.get(key);
    return new Company(this.domain, value);
  }
}