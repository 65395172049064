
import RcObject from '@/domain/common/RcObject.js';

export default class RcPayload extends RcObject {
  
  constructor(data) {
    super(data);
  }

  valuesFor(key) {
    var map = this.data[key];
    if (map) {
      return new RcObject(map);
    }
    return new RcObject({});
  }
  
  count() {
    var value = this.data['count'];
    return value;
  }
  
  withCount(count) {
    this.data["count"] = count;
    return this;
  }

  total() {
    var value = this.data['total'];
    return value;
  }
  
  hasMultiCompany() {
    var value = this.data['multicompany'];
    if (value) {
      return true;
    }
    return false;
  }
}