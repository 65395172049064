/*import Vue from 'vue'*/
import EventUtils from '@/utils/EventUtils.js';
import MapUtils from '@/utils/MapUtils.js';
import ConstUtils from '@/utils/ConstUtils.js'

const state = {
  list: [],
  map: {},
  lastEvent: {},
};

const getters = {
    InvoiceSettingsStorage: state => state,
    InvoiceSettingsStorage_lastEvent: state => state.lastEvent,
};

const actions = {
};

const mutations = {
  SOCKET_ONMESSAGE (state, event)  {
    if (event.status == EventUtils.STATUS.COMPLETE && event.statusCode == EventUtils.CODE.OK) {
      if (event.Payload && event.Payload.InvoiceSettingsMap) {
        var items = event.Payload.InvoiceSettingsMap;
        state.map = MapUtils.updateMap(items, state.map);
        state.list = Object.values(state.map);
        state.lastEvent = event;
      }
      if (event.Payload && event.Payload.InvoiceSettings) {
        var obj = event.Payload.InvoiceSettings;
        state.map[obj[ConstUtils.FIELDS.ID]] = obj;
        state.list = Object.values(state.map);
        state.lastEvent = event;
      }
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};