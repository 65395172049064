
export default class StringUtils {
  
  static e(original, other) {
    return original === other;
  }
  
  static split(toSplit, splitOn) {
    let splits = [];
    if (StringUtils.contains(toSplit, splitOn)) {
      splits = toSplit.split(splitOn);
    } else {
      splits.push(toSplit);
    }
    return splits;
  }

  static eic(original, other) {
    return StringUtils.e(StringUtils.lower(original), StringUtils.lower(other));
  }

  static cic(original, other) {
    return StringUtils.contains(StringUtils.lower(original), StringUtils.lower(other));
  }

  static lower(value) {
    return StringUtils.toString(value).toLowerCase();
  }

  static equals(original, other) {
    return original === other;
  }
  
  static isOk(value) {
    if (!StringUtils.isEmpty(value)) {
      return true;
    }
    return false;
  }
  
  static isNull(value) {
    if (value == null || value == 'undefined' || value === "null" || value == undefined) {
      return true;
    }
    return false;
  }
  
  static isNotEmpty(value) {
    return !StringUtils.isEmpty(value);
  }

  static isEmpty(value) {
    if (value == null) {
      return true;
    }
    if (value.trim) {
      return value.trim().length == 0;
    }
    return false;
  }
  
  static isEven(position) {
    if (position % 2 == 0) {
      return true;
    }
    return false;
  }
  
  static isEmail(value) {
    if (!StringUtils.isOk(value)) {
      return false;
    }
    return value.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
  }
  
  static contains(src, value) {
    if (StringUtils.isEmpty(src)) {
      return false;
    }
    return src.includes(value);
  }
  
  static startsWith(src, value) {
    if (StringUtils.isEmpty(src)) {
      return false;
    }
    return src.startsWith(value);
  }

  static hashCode(s) {
    var h = 0, l = s.length, i = 0;
    if ( l > 0 ) {
      while (i < l) {
        h = (h << 5) - h + s.charCodeAt(i++) | 0;
      }
    }
    if (h > 0) {
      h = h + 31;
    }
    return h + "";    
  }

  static remove(msg, toFind) {
    return StringUtils.replace(msg, toFind, "");
  }

  static replace(msg, toFind, replaceWith) {
    if (StringUtils.isEmpty(msg)) {
      return "";
    }
    
    if (StringUtils.isEmpty(toFind)) {
      return msg;
    }
    
    if (StringUtils.isEmpty(replaceWith)) {
      replaceWith = "";
    }
    
    return msg.replace(toFind, replaceWith);
  }
  
  static format(msg, params) {
    var value = msg;
    if (params) {
      for (var i = 0; i < params.length; i++) {
        var param = params[i] + "";
        value = StringUtils.replace(value, "{" + i + "}", param);
      }
    }
    
    return value;
  }
  
  static toString(value, defaultValue) {
    if (value) {
      return value.toString();
    }
    
    if (value && value === 0) {
      return "0";
    }

    if (defaultValue) {
      return defaultValue;
    }
    
    if (value == null) {
      return "";
    }

    return "";
  }
  
  static displayAsMoney(value) {
    return "$" + parseFloat(StringUtils.toString(value,"0")).toFixed(2);
  }

  static trim(value) {
    return StringUtils.toString(value).trim();
  }
  
  static uppercase(value) {
    return StringUtils.toString(value).toUpperCase();
  }

  static titleCase(value) {
    value = StringUtils.toString(value);
    if (StringUtils.isEmpty(value)) {
      return value;
    }
    if (value.length == 1) {
      return value.toUpperCase();
    }
    return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase(); 
  }
}