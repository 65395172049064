<template>

  <div class="rc-container" :class="additional()">
    <slot />
  </div>

</template>

<script>

import { mapGetters } from 'vuex'
import StringUtils from "@/utils/StringUtils.js";

export default {
  name: 'portals-shared-library-box-box',
  props: {
    borderStyle:  { type: String, default: "" },
    cmpt:         { type: String, default: "box" },
    backgroundColour: { type: String, default: "" },
    even:         { type: String, default: null},
    borderRadius: { type: String, default: "" },
    elevation:    { type: String, default: "" },
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters([
                 ]),
  },
  methods: {
    additional: function() {
      var value = "";
      if (this.even != null) {
        value += this.even == "true" ? "rc-border-thin-even " : "rc-border-thin ";
      } 
      if (!StringUtils.isEmpty(this.borderStyle)) {
        value += this.borderStyle + " ";
      }
      if (!StringUtils.isEmpty(this.backgroundColour)) {
        value += "rc-" + this.backgroundColour + " ";
      }
      if (!StringUtils.isEmpty(this.borderRadius) && this.borderRadius != "none") {
        value += "rc-border-radius-" + this.borderRadius + " ";
      }
      if (!StringUtils.isEmpty(this.elevation) && this.elevation != "none") {
        value += "rc-elevation-" + this.elevation + " ";
      }
      return value;
    },
  },
}
</script>