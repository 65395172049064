import RcDomainObject from '@/domain/common/RcDomainObject.js';

import RcPayload from './RcPayload.js';
import RcError from '../../exceptions/RcError.js';
import StringUtils from "@/utils/StringUtils.js";

export default class RcResponseEvent extends RcDomainObject {

  static Fields = {
    PAYLOAD: 'Payload',
  }

  constructor(domain, data) {
    super(domain, data);
  }

  name() {
    return this.data.eventName;
  }

  is(name) {
    return this.data.eventName == name;
  }

  hasError() {
    return this.data.error != null;
  }

  error() {
    return this.data.error;
  }

  rcError() {
    return new RcError(this.domain, this.error());
  }

  event() {
    var event = this.domain.events().findByData(this.data);
    return event;
  }

  progressMessage() {
    return this.getString("progressMessage");
  }

  payload() {
    return new RcPayload(this.get(RcResponseEvent.Fields.PAYLOAD));
  }

  withPayload(payload) {
    this.put(RcResponseEvent.Fields.PAYLOAD, payload);
    return this;
  }

  status() {
    return this.getString("status");
  }

  isStatusIntermediate() {
    return StringUtils.eic("intermediate", this.status());
  }

  isStatusComplete() {
    return StringUtils.eic("complete", this.status());
  }

  user() {
    return this.data.User;
  }
}

