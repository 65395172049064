export default class CompanyUtils {
  
  constructor() {
    //
  }
  
  static SortBy_name = function(a,b) {
    return CompanyUtils.SortBy_nameAz(a, b);
  }

  static SortBy_nameAz = function(a,b) {
    return CompanyUtils.SortBy("name", a, b);
  }

  static SortBy_nameZa = function(a,b) {
    return CompanyUtils.SortByDesc("name", a, b);
  }

  static SortBy_createdDateAz = function(a,b) {
    return CompanyUtils.SortBy("createdDate", a, b);
  }

  static SortBy_createdDateZa = function(a,b) {
    return CompanyUtils.SortByDesc("createdDate",a, b);
  }

  static SortBy_contractStartAz = function(a,b) {
    return CompanyUtils.SortBy("contractStart", a, b);
  }

  static SortBy_contractStartZa = function(a,b) {
    return CompanyUtils.SortByDesc("contractStart", a, b);
  }

  static SortBy = function(methodName, a, b) {
    if (a == null || b == null) {
      return 0;
    }
    
    let aValue = CompanyUtils.resultFromFunction(a[methodName]());
    let bValue = CompanyUtils.resultFromFunction(b[methodName]());

    if (aValue < bValue) {
      return -1;
    }

    if (aValue > bValue) {
      return 1;
    }

    return 0;
  }

  static SortByDesc = function(methodName, a, b) {
    if (a == null || b == null) {
      return 0;
    }

    let aValue = CompanyUtils.resultFromFunction(a[methodName]());
    let bValue = CompanyUtils.resultFromFunction(b[methodName]());

    if (aValue < bValue) {
      return 1;
    }

    if (aValue > bValue) {
      return -1;
    }

    return 0;
  }

  static resultFromFunction = function(aFunctionOrValue) {
    if (typeof aFunction === 'function') {
      return CompanyUtils.resultFromFunction(aFunctionOrValue());
    }
    if (aFunctionOrValue.time) {
      return aFunctionOrValue.time();
    }
    return aFunctionOrValue
  }
}