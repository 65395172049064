<template>

  <Row cmpt="ValueRow" :borderStyle="showBorder ? 'rc-border-thin' : ''" v-on:click="pressed">
    <Column>
      <Box>
        <Row v-if="spacer">
          <Column :width="15">
            <div class="rc-font-small">
              <div > 
                <div class="rc-font-small rc-text-label">
                  <slot name="space"></slot>
                </div>
              </div>
            </div>
          </Column>
        </Row>
        <Row>
          <Column v-if="hasLeft" :width="left" v-on:click="pressed"></Column>
          <Column :width="middle" class="rc-text-value" :class="styling()" v-on:click="pressed">
            <slot />
          </Column>
          <Column v-if="hasRight" :width="right" v-on:click="pressed"></Column>
        </Row>
      </Box>
    </Column>
  </Row>

</template>

<script>

import { mapGetters } from 'vuex'

import Row from "@/portals/shared/library/box/Row.vue";
import Box from "@/portals/shared/library/box/Box.vue";
import Column from "@/portals/shared/library/box/Column.vue";

export default {
  name: 'portals-shared-library-value-value-row',
  components: {
    Row, Box,
    Column,
  },
  props: {
    align: { type: String, default: "centre" },
    cmpt: { type: String, default: "label" },
    fontSize: { type: String, default: "medium" },
    showBorder: { type: Boolean, default: false },
    spacer: { type: Boolean, default: false },
    fontColour: { type: String, default: "" },
    left:     { type: Number, default: 0 },
    right:    { type: Number, default: 0 },

  },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters([
                   'domain',
                 ]),
    middle: function() {
      return 15 - this.left - this.right;
    },
    hasLeft: function() {
      return this.left > 0;
    },
    hasRight: function() {
      return this.rigth > 0;
    },
  },
  methods: {
    styling: function() {
      var value = "";
      
      if (this.align === "left") {
        value += "rc-text-left";
        
      } else if (this.align === "right") {
        value += "rc-text-right";
        
      } else {
        value += "rc-text-centre";
      }
      
      if (this.fontSize) {
        value += " rc-text-" + this.fontSize;
      }
      if (this.fontColour != "") {
        value += " rc-text-value-" + this.fontColour;
      }
      return value;
    },
    pressed: function() {
      this.$emit('click');
    },
  },
}
</script>