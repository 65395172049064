
import RcDomainMap from "../../common/RcDomainMap.js";
import Feature from './Feature.js';

export default class FeatureMap extends RcDomainMap {
  static MODEL_NAME = Feature.MODEL_NAME  + "Map";
  
  constructor(domain, data) {
    super(domain, data, FeatureMap.MODEL_NAME);
  }

  allowsSso() {
    var keys = this.keys();
    for (var index = 0; index < keys.length; index++) {
      var key = keys[index];
      var feature = new Feature(this.domain, this.get(key));
      if (feature.isSso()) {
        return true;
      }
    }
    return false;
  }

  allowsOffline() {
    var keys = this.keys();
    for (var index = 0; index < keys.length; index++) {
      var key = keys[index];
      var feature = new Feature(this.domain, this.get(key));
      if (feature.isOffline()) {
        return true;
      }
    }
    return false;
  }

  allowsInspections() {
    var keys = this.keys();
    for (var index = 0; index < keys.length; index++) {
      var key = keys[index];
      var feature = new Feature(this.domain, this.get(key));
      if (feature.isInspections()) {
        return true;
      }
    }
    return false;
  }
  
  allowsCheckIns() {
    var keys = this.keys();
    for (var index = 0; index < keys.length; index++) {
      var key = keys[index];
      var feature = new Feature(this.domain, this.get(key));
      if (feature.isCheckIns()) {
        return true;
      }
    }
    return false;
  }
  
  allowsCheckLists() {
    var keys = this.keys();
    for (var index = 0; index < keys.length; index++) {
      var key = keys[index];
      var feature = new Feature(this.domain, this.get(key));
      if (feature.isCheckLists()) {
        return true;
      }
    }
    return false;
  }

  allowsChecklistsForVehicles() {
    var keys = this.keys();
    for (var index = 0; index < keys.length; index++) {
      var key = keys[index];
      var feature = new Feature(this.domain, this.get(key));
      if (feature.isChecklistsForVehicles()) {
        return true;
      }
    }
    return false;
  }
  
  allowsTimesheets() {
    var keys = this.keys();
    for (var index = 0; index < keys.length; index++) {
      var key = keys[index];
      var feature = new Feature(this.domain, this.get(key));
      if (feature.isTimesheets()) {
        return true;
      }
    }
    return false;
  }
  
  allowsVehicles() {
    var keys = this.keys();
    for (var index = 0; index < keys.length; index++) {
      var key = keys[index];
      var feature = new Feature(this.domain, this.get(key));
      if (feature.isVehicles()) {
        return true;
      }
    }
    return false;
  }
  
  allowsHome() {
    var keys = this.keys();
    for (var index = 0; index < keys.length; index++) {
      var key = keys[index];
      var feature = new Feature(this.domain, this.get(key));
      if (feature.isHome()) {
        return true;
      }
    }
    return false;
  }
  
  allowsUsers() {
    var keys = this.keys();
    for (var index = 0; index < keys.length; index++) {
      var key = keys[index];
      var feature = new Feature(this.domain, this.get(key));
      if (feature.isUsers()) {
        return true;
      }
    }
    return false;
  }
  
  allowsMessages() {
    var keys = this.keys();
    for (var index = 0; index < keys.length; index++) {
      var key = keys[index];
      var feature = new Feature(this.domain, this.get(key));
      if (feature.isMessages()) {
        return true;
      }
    }
    return false;
  }
  
  allowsCompliance() {
    var keys = this.keys();
    for (var index = 0; index < keys.length; index++) {
      var key = keys[index];
      var feature = new Feature(this.domain, this.get(key));
      if (feature.isCompliance()) {
        return true;
      }
    }
    return false;
  }

  allowsEquipment() {
    var keys = this.keys();
    for (var index = 0; index < keys.length; index++) {
      var key = keys[index];
      var feature = new Feature(this.domain, this.get(key));
      if (feature.isEquipment()) {
        return true;
      }
    }
    return false;
  }

  allowsEquipmentInspections() {
    var keys = this.keys();
    for (var index = 0; index < keys.length; index++) {
      var key = keys[index];
      var feature = new Feature(this.domain, this.get(key));
      if (feature.isEquipmentInspections()) {
        return true;
      }
    }
    return false;
  }


  allowsProfileUpdate() {
    var keys = this.keys();
    for (var index = 0; index < keys.length; index++) {
      var key = keys[index];
      var feature = new Feature(this.domain, this.get(key));
      if (feature.isProfileUpdate()) {
        return true;
      }
    }
    return false;
  }
  
  find() {
    var keys = this.keys();
    var found = new FeatureMap(this.domain);
    for (var index = 0; index < keys.length; index++) {
      var key = keys[index];
      var data = this.domain.features().findById(key);
      var feature = new Feature(this.domain, data);
      found.add(feature);
    }
    
    return found;
  }
  
  copy() {
    var data = this._copy();
    return new FeatureMap(this.domain, data);
  }
}