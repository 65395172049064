import EventUtils from '@/utils/EventUtils.js';
import MapUtils from '@/utils/MapUtils.js';
import ConstUtils from '@/utils/ConstUtils.js';

const state = {
  list: [],
  map: {},
  pages: {},
  lastEvent: {},
};

const getters = {
  InspectionStorage_Pages: state => state.pages,
  InspectionStorage_lastEvent: state => state.lastEvent,
};

const actions = {
  InspectionStorage_storePage({ commit }, page) {
    commit('storePage', page);
  },
};

const mutations = {
  storePage(state, page) {
    let key = page.filter + "." + page.number;
    state.pages[key] = page.data;
  },
  SOCKET_ONMESSAGE(state, event) {
      if (event.status == EventUtils.STATUS.COMPLETE && event.statusCode == EventUtils.CODE.OK) {
        if (event.Payload && event.Payload.VehicleInspectionMap) {
          var items = event.Payload.VehicleInspectionMap;
          state.map = MapUtils.updateMap(items, state.map);
          state.list = Object.values(state.map);
          state.lastEvent = event;
        }
        if (event.Payload && event.Payload.VehicleInspection) {
          var obj = event.Payload.VehicleInspection;
          state.map[obj[ConstUtils.FIELDS.ID]] = obj;
          state.list = Object.values(state.map);
          state.lastEvent = event;
        }
      }
    },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
